import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { PagesModule } from './pages/pages.module';
import { ScrollSpyDirective } from './directives/scrollSpy.directive';
import { ScrollAnimationDirective } from './directives/scroll-animation.directive';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { BackgroundColorSpyDirective } from './directives/background-color-spy.directive';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { BrowserModule } from '@angular/platform-browser';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ContactSectionComponent } from './components/contact-section/contact-section.component';
@NgModule({
    declarations: [
        AppComponent,
        ScrollSpyDirective,
        ScrollAnimationDirective,
        NavigationBarComponent,
        BackgroundColorSpyDirective,
    ],
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        AppRoutingModule,
        PagesModule,
        AngularSvgIconModule.forRoot(),
        NgxGoogleAnalyticsModule.forRoot('G-5XM501RS80'),
        NgxGoogleAnalyticsRouterModule,
        ReactiveFormsModule,
        BrowserModule,
    ],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
