import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  @Input() formspreeEndpoint: string = 'https://formspree.io/f/mnnpjeed';
  @Input() title: string = 'Contact Form';
  @Output() formSubmitted = new EventEmitter<any>();
  
  contactForm: FormGroup;
  isSubmitting = false;
  submitSuccess = false;
  submitError: string | null = null;

  constructor(
    private fb: FormBuilder,
    private http: HttpClient
  ) {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.pattern('[+]?[0-9 ]{10,15}')], // Optional but with pattern
      message: ['', Validators.required],
      consent: [false, Validators.requiredTrue] // Required checkbox
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.contactForm.valid) {
      this.isSubmitting = true;
      this.submitError = null;
      
      // Just submit the form data directly
      this.http.post(this.formspreeEndpoint, this.contactForm.value)
        .subscribe({
          next: (response) => {
            this.isSubmitting = false;
            this.submitSuccess = true;
            this.formSubmitted.emit(this.contactForm.value);
            this.contactForm.reset();
            
            // Reset form and provide success message that disappears after 5 seconds
            this.contactForm.get('consent')?.setValue(false);
            setTimeout(() => this.submitSuccess = false, 5000);
          },
          error: (error) => {
            this.isSubmitting = false;
            this.submitError = 'There was an error sending your message. Please try again.';
            console.error('Formspree submission error:', error);
          }
        });
    }
  }
}