import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss']
})
export class ContactSectionComponent {
  @Input() formspreeEndpoint: string = 'https://formspree.io/f/mnnpjeed';
  @Output() formSubmitted = new EventEmitter<any>();
  
  onFormSubmit(formData: any) {
    this.formSubmitted.emit(formData);
  }
}