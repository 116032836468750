import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { PublicSafetyComponent } from './pages/public-safety/public-safety.component';
import { DefenseComponent } from './pages/defense/defense.component';

const routes: Routes = [
    { path: '', component: LandingPageComponent },
    // { path: '**', redirectTo: '', pathMatch: 'full' },
    { path: 'sonic-guard-defense', component: DefenseComponent },
    { path: 'sonic-guard-public-safety', component: PublicSafetyComponent },
];

@NgModule({
    imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
    exports: [],
})
export class AppRoutingModule {}
