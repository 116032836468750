import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SectionComponent } from '../components/section/section.component';
import { CardComponent } from '../components/card/card.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ButtonComponent } from '../components/button/button.component';
import { ScenariosComponent } from '../components/scenarios/scenarios.component';
import { ContactFormComponent } from '../components/contact-form/contact-form.component';
import { ContactSectionComponent } from '../components/contact-section/contact-section.component';
import { FooterComponent } from '../components/footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { PublicSafetyComponent } from './public-safety/public-safety.component';
import { DefenseComponent } from './defense/defense.component';
@NgModule({
    imports: [CommonModule, BrowserModule, FormsModule, RouterModule, ReactiveFormsModule, HttpClientModule, AngularSvgIconModule.forRoot()],
    declarations: [
        SectionComponent,
        CardComponent,
        LandingPageComponent,
        ButtonComponent,
        ScenariosComponent,
        ContactFormComponent,
        ContactSectionComponent,
        FooterComponent,
        PublicSafetyComponent,
        DefenseComponent,
    ],
    exports: [
        ContactSectionComponent,
        FooterComponent,
    ],
    providers: [],
})
export class PagesModule {}
